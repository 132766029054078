<template>
    <div class="CharMax">
        <!-- 头部 margin-right:50vh;-->
        <div class="CharTouBu">
            <!-- 按钮 -->
            <img @click="GoHome" src="../../../assets/ConstructionLandscape/Home.png" alt=""
                style="height:70%;cursor: pointer;margin-right:15vw;">
            <!-- 标题 -->
            <div class="CharNavTitle">
                <div>红色征集</div>
            </div>
            <!-- 按钮 margin-left:50vh-->
            <img @click="GoLogin" src="../../../assets/ConstructionLandscape/GeRen.png" alt=""
                style="height: 70%;;cursor: pointer;margin-left:15vw">
        </div>
        <!-- 面包屑 -->
        <div class="CharCrumbs">
            <div><span>当前位置：</span><span style="cursor: pointer;" @click="culture()">红色文化馆</span></div>
            <div style="height:100%;display: flex;align-items: center;"> <img
                    src="../../../assets/RedCultureCenter/arrows.png" alt=""
                    style="height:50%;margin-top:0.2vh;margin-left:1vh;"></div>
            <div style="margin-left: 1vh;"><span style="cursor: pointer;">红色征集</span></div>
            <div style="height:100%;display: flex;align-items: center;"> <img
                    src="../../../assets/RedCultureCenter/arrows.png" alt=""
                    style="height:50%;margin-top:0.2vh;margin-left:1vh;"></div>
            <div style="margin-left: 1vh;"><span style="cursor: pointer;">红色人物</span></div>
        </div>
        <!-- 输入框 -->
        <div class="CharBox">
            <!-- 下拉框 -->
            <div class="CharDrop">
                <div class="wrap" style="width: 100%;">
                    <div class="dark-select">
                        <el-select v-model="options[0].label"  :popper-append-to-body="false"
                            style="width:100%;height:100%">
                            <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value"
                                class="provinces_select" @click="shadow(item.value)">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <!-- 下面 -->
            <div class="RedBelow">
                <!-- 左边 -->
                <div class="RedLeft">
                    <!-- 名称 -->
                    <div class="RedName">
                        <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">人物名：</div>
                        <input type="text" v-model="CharForm.name">
                    </div>
                    <!-- 校验 -->
                    <!-- <div style="font-size:1vh;margin-left:3vh;" v-if="name">名称不可以为空</div> -->
                    <!-- 人物出身地： -->
                    <!-- <div class="RedName" style="margin-top:2vh;">
                        <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">人物出身地：</div>
                        <input type="text" @blur="born" v-model="CharForm.born">
                    </div> -->
                    <!-- 校验 -->
                    <!-- <div style="font-size:1.7vh;margin-left:3vh;" v-if="CharBorn">出生地不可以为空</div> -->
                    <!-- 人物出生时间： -->
                    <!-- <div class="RedName" style="margin-top:2vh;">
                        <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">人物出生时间：</div>
                        <input type="text" @blur="time" v-model="CharForm.time">
                    </div> -->
                    <!-- 校验 -->
                    <!-- <div style="font-size:1.7vh;margin-left:3vh;" v-if="CharTime">出生时间不可以为空</div> -->
                    <!-- 人物出生时间： -->
                    <div class="RedNameOne" >
                        <!-- 所属景区: -->
                        <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">所属景区：</div>
                        <el-select v-model="CharForm.scenic" placeholder="请选择" style="width:70%;height:75%;">
                            <el-option v-for="(item, index) in ScenicArea" :key="index" :label="item.scenicName"
                                :value="item.scenicId" class="provinces_select" @click="ScenicId(item.scenicId)">
                            </el-option>
                        </el-select>
                        <!-- <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">所属景区：</div>
                        <input type="text" @blur="scenic" v-model="CharForm.scenic"> -->
                    </div>
                    <div class="CharFlexOne">
                        <!-- 视频名称-->
                        <div class="RedPicture">
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                            <input type="text" v-model="CharForm.VideoName" >
                        </div>
                        <!-- 视频名称 -->
                        <div class="RedPicture">
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                            <input type="text" v-model="CharForm.VideoNameOne">
                        </div>
                    </div>
                    <!-- 上传 -->
                    <div class="RedUploading">
                        <!-- 上传视频/图片： -->
                        <div class="RedCharacters">
                            <!-- 图片 -->
                            <!-- <div class="RedPictureOne">上传图片：</div> -->
                            <!-- 图片 -->
                            <div class="RedPictureOne">上传视频：</div>
                        </div>
                        <!-- 图片 商品 -->
                        <div class="RedFlex">
                            <!-- 上传视频 -->
                            <div class="RedVideo">

                                <el-upload action="http://ccsu.lzxx8848.com/api/tools/fileUpload/videoUpload"
                                    :limit="limitCountImgOne" class="dl-avatar-uploader-min square"
                                    :class="{ uoloadBtn: showBtnDealImgOne, disUoloadBtn: noneBtnImgOne }"
                                    :on-change="onChangeUploadOne" list-type="picture-card" :file-list="FileListOne"
                                    :headers="headers" :data="VideoData" :on-preview="handlePreviewOne"
                                    :on-exceed="handleExceedCover" :before-upload="beforeImageUploadOne"
                                    :on-remove="coverFileRemoveOne" :on-success="coverFileSuccessOne">
                                    <div style="width:100%;height:100%;"> <img
                                            src="../../../assets/PersonalCenter/collect/uploading.png" alt=""
                                            style="width:100%;height:100%;"> </div>
                                </el-upload>
                                <el-dialog v-model="dialogVisibleOne">
                                    <video controls="controls" style="width: 100%;">
                                        <source :src="dialogImageUrlOne" type="video/mp4" />
                                    </video>
                                    <!-- <video :src="dialogImageUrlOne" class="video" controls="controls" ></video> -->
                                    <!-- <img w-full class="image onsite-img" :src="dialogImageUrl" /> -->
                                </el-dialog>

                            </div>
                        </div>
                       
                    </div>
                     <!-- 视频简介 -->
                     <div class="ChaVideoIntrodu">
                        <div class="ChaTitle">视频简介1：</div>
                        <textarea v-model="videoForm[0].VideoPoint" placeholder="请输入简介" class="RedCollText">

                        </textarea>
                        <div class="ChaTitle">视频简介2：</div>
                        <textarea v-model="videoForm[1].VideoPoint" placeholder="请输入简介" class="RedCollText">

                        </textarea>
                     </div>
                    <!-- 校验 -->
                    <!-- <div style="font-size:1.7vh;margin-left:3vh;" v-if="CharScenic">所属景区不可以为空</div> -->
                    <!-- 输入 -->
                    <!-- <div class="RedTextBox">
                        <textarea v-model="CharForm.inputPoint" placeholder="请输入简介" class="RedCollText">

                                  </textarea>
                    </div> -->

                </div>
                <!-- 右边 -->
                <div class="RedRight">
                     <div class="RedTextBox">
                        <div class="ChaTitle">人物简介：</div>
                        <textarea v-model="CharForm.inputPoint" placeholder="请输入简介" class="RedCollText">
                         
                        </textarea>
                    </div>
                   
                    <!-- 上传 -->
                    
                    <!-- 图片，视频名称 -->
                    <div class="CharFlexTwo">
                        <!-- 图片名称-->
                        <div class="RedPicture">
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                            <input type="text"  v-model="CharForm.ImgName">
                        </div>
                        <!-- 视频名称 -->
                        <div class="RedPicture">
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                            <input type="text" v-model="CharForm.ImgNameOne">
                        </div>
                    </div>
                    <div class="CharRight">
                        <div class="RedCharacters">
                            <!-- 图片 -->
                            <div class="RedPictureOne">上传图片：</div>
                        </div>
                        <!-- 上传图片 -->
                        <div class="RedRedPictureTwo">

                            <el-upload action="http://ccsu.lzxx8848.com/api/tools/fileUpload/imgUpload"
                                :limit="limitCountImg" class="dl-avatar-uploader-min square"
                                :class="{ uoloadBtn: showBtnDealImg, disUoloadBtn: noneBtnImg }" :on-change="onChangeUpload"
                                list-type="picture-card" :file-list="fileList" :headers="headers" :data="FormData"
                                :on-preview="handlePreview" accept="image/jpeg,image/png,image/jpg"
                                :on-exceed="handleExceedCover" :before-upload="beforeImageUpload"
                                :on-remove="coverFileRemove" :on-success="coverFileSuccess">
                                <div style="width:100%;height:100%;"> <img
                                        src="../../../assets/PersonalCenter/collect/uploading.png" alt=""
                                        style="width:100%;height:100%;"> </div>
                            </el-upload>
                            <el-dialog v-model="dialogVisible">
                                <img w-full class="image onsite-img" :src="dialogImageUrl" />
                            </el-dialog>


                        </div>
                    </div>
                    <!-- 视频简介 -->
                    <div class="ChaVideoIntrodu">
                        <div class="ChaTitle">图片简介1：</div>
                        <textarea v-model="imgsOne[0].ImgsPoint" placeholder="请输入简介" class="RedCollText">

                        </textarea>
                        <div class="ChaTitle">图片简介2：</div>
                        <textarea v-model="imgsOne[1].ImgsPoint" placeholder="请输入简介" class="RedCollText">

                        </textarea>
                     </div>
                    <!-- 输入 -->
                    <!-- <div class="RedTextBox">
                        <textarea v-model="inputPoint" placeholder="请输入简介" class="RedCollText">

                              </textarea>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- 提交按钮 -->
        <div @click="submit()"
            style="width:8%;height:4.5%;background-color:rgba(41, 98, 182, 1);display: flex;justify-content: center; align-items: center;margin: 0 auto;margin-top:2vh;color: rgba(255, 255, 255, 1);cursor: pointer;font-size:2vh;">
            保存</div>
    </div>
</template>

<script>
import { time } from 'console';
import { title } from 'process';
export default {
    data() {
        return {
            // 上传图片
            dialogImageUrl: '',
            coversList: [],
            limitCountImg: 2,
            showBtnDealImg: true,
            noneBtnImg: false,
            dialogVisible: false,
            FormData: {   // 额外参数
                useType: 'personage',
                imageFile: '',
            },
            headers: { Authorization: 'Bearer ' + this.$cookies.get("token") },
            //  上传视频
            // 视频上传
            videoList: [],
            dialogImageUrlOne: '',
            limitCountImgOne: 2,
            showBtnDealImgOne: true,
            noneBtnImgOne: false,
            dialogVisibleOne: false,
            VideoData: {
                useType: 'personage',
                videoFile: '',
            },
            CharForm: {
                //名称
                name: '',
                //简介
                inputPoint: '',
                // 视频名称
                VideoName:'',
                VideoNameOne:'',
                // 图片名称
                ImgName:'',
                ImgNameOne:'',
                //出生的
                // born: '',
                // 出生时间   
                // time: '',
                //所属景区
                scenic: '',
            },
            videoForm:[
                {
                    // 视频简介
                  VideoPoint:'',
                },
                {
                    // 视频简介
                  VideoPoint:'',
                }
                ],
            
            CharScenic: '',
            options: [
                {
                    value: 1,
                    label: "红色人物",
                },
                {
                    value: 2,
                    label: "红色影像",
                },
            ],
            // 查景区
            ScenicArea: [],
            id:'',
            // scenicSpot:[],
            value: "",
            // 遍历的视频集
            videos:[
                {
                    title:'',
                    resourceUrl:'',
                    summary:'',
                }
                ,
                {
                    title:'',
                    resourceUrl:'',
                    summary:'',
                }
            ],
            videoOne:[],
            // 遍历的图片集
            IamgeOne:[],
            imgsOne:[
                {
                    ImgsPoint:'',
                },
                {
                    ImgsPoint:'',
                }
            ],
            imgs:[
                {
                    title:'',
                    resourceUrl:'',
                    summary:'',
                }
                ,
                {
                    title:'',
                    resourceUrl:'',
                    summary:'',
                }
            ]
        }
    },
    mounted() {
        this.FindScenic()
    },
    methods: {
        // 返回个人中心
        GoLogin() {
            this.$router.push('/login');
        },
        // 返回首页
        GoHome() {
            this.$router.push('/');
        },
        //返回红色文化馆
        culture() {
            this.$router.push('/RedCultureCenter');
        },
        //点击跳转红色影像征集
        shadow(value) {
            if (value == 2) {
                this.$router.push('/TheRedImage');
            }
        },
       
        //上传图片
        onChangeUpload(file, fileList) {
            this.FormData.imageFile = file.raw
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        beforeImageUpload(rawFile) {
            if (rawFile.size / 1024 / 1024 > 2) {
                this.$message.error("单张图片大小不能超过2MB!");
                return false;
            }
            return true;
        },
        coverFileSuccess(response, file, fileList) {
            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.coversList.push(obj);
                this.IamgeOne.push(obj.url.fileUrl)
                this.$message({
                        message: '上传成功',
                        type: 'success'
                      });
            } else {
                this.$message.error(`图片${response.msg}上传失败`)  
            }
            if(response.code === 401){
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                });
                this.$router.push('/login');
            }


        },
        coverFileRemove(file, fileList) {
            this.coversList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.coversList.splice(index, 1);
                }
            });
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        handleExceedCover(files, fileList) {
            this.$message.error({
                message: `上传图片数量超出限制！`,
                type: "error",
            });
        },
        handlePreview(uploadFile) {
            this.dialogImageUrl = uploadFile.url;
            this.dialogVisible = true;
        },
        // 上传视频
        onChangeUploadOne(file, FileListOne) {
            this.VideoData.videoFile = file.raw
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        beforeImageUploadOne(rawFile,) {
            if (rawFile.size / 1024 / 1024 > 30) {
                this.$message.error("单个视频大小不能超过30MB!");
                return false;
            }
            if ([
                "video/mp4",
                "video/ogg",
                "video/flv",
                "video/avi",
                "video/wmv",
                "video/rmvb",
                "video/mov",
            ].indexOf(rawFile.type) == -1     //控制格式
            ) {
                this.$message.error("请上传正确的视频格式!");
                return false;
            }
            return true;
        },
        coverFileSuccessOne(response, file, FileListOne) {
         
            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.videoList.push(obj);
                this.videoOne.push(obj.url.fileUrl) 
                // for(let i=0; i< this.videoList.length; i++){
                //     this.videoOne.push(this.videoList[i].url.fileUrl)
                // }
                this.$message({
                        message: '上传成功',
                        type: 'success'
                      });
            } else {
                this.$message.error(`视频上传失败`)
            }
            if(response.code === 401){
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                });
                this.$router.push('/login');
            }
        },


        coverFileRemoveOne(file, FileListOne) {
            this.videoList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.videoList.splice(index, 1);
                }
            });
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        handleExceedCoverOne(files, FileListOne) {
            this.$message.error({
                message: `上传视频数量超出限制！`,
                type: "error",
            });
        },
        handlePreviewOne(uploadFile) {
            this.dialogImageUrlOne = uploadFile.url;
            this.dialogVisibleOne = true;
        },
        // 查所以景区.
        FindScenic() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url:  this.$Schttp+'/vtp/app/scenic/scenicIndex',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.ScenicArea = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 点击查id
        ScenicId(id) {
            this.id = id
        },
        // 点击提交
           submit() {
            // 昵称校验
            if (this.CharForm.name == '') {
                this.$message({
                    message: '昵称不可为空',
                    type: 'error'
                });
                return
            }
            // 所属景区
            if (this.CharForm.scenic == '') {
                this.$message({
                    message: '所属景区不可为空',
                    type: 'error'
                });
                return
            }
            // 简介校验
            if(this.CharForm.inputPoint == ''){
                this.$message({
                    message: '简介不可为空',
                    type: 'error'
                });
                return
            }
            // 上传视频校验
            if(this.videoList == '' && this.CharForm.VideoName == ''){
                this.$message({
                    message: '最少上传一个视频与名称',
                    type: 'error'
                });
                return
            }
            // 上传图片校验
            if(this.coversList == '' && this.CharForm.ImgName == ''){
                this.$message({
                    message: '最少上传一张图片与名称',
                    type: 'error'
                });
                return
            }
            // 图片集
            this.imgs[0].title = this.CharForm.ImgName
            this.imgs[1].title = this.CharForm.ImgNameOne
            this.imgs[0].resourceUrl = this.IamgeOne[0]
            this.imgs[1].resourceUrl = this.IamgeOne[1]
            this.imgs[0].summary = this.imgsOne[0].ImgsPoint
            this.imgs[1].summary = this.imgsOne[1].ImgsPoint
            // 视频集
            this.videos[0].title = this.CharForm.VideoName
            this.videos[1].title = this.CharForm.VideoNameOne
            this.videos[0].resourceUrl = this.videoOne[0]
            this.videos[1].resourceUrl = this.videoOne[1]
            this.videos[0].summary = this.videoForm[0].VideoPoint
            this.videos[1].summary = this.videoForm[1].VideoPoint
            var axios = require('axios');
            var config = {
                method: 'post',
                url:  this.$Schttp+'/vtp/app/personage/save',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    scenicId:this.id,
                    title:this.CharForm.name,
                    summary:this.CharForm.inputPoint,
                    imgs:this.imgs,
                    videos:this.videos,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                        message: '提交成功',
                        type: 'success'
                      });
                      this.$router.push('/RedCultureCenter');
                    }
                   
                })
                .catch(function (error) {
                });
        }
    }
}
</script>

<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
}

.CharMax {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/RedCultureCenter/RedCultureCenterQuanBG.png');
    background-size: 100% 100%;
    color: rgb(22, 245, 255, );

    .CharTouBu {
        width: 100%;
        height: 14%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .CharNavTitle {
            margin-top: -7vh;
            font-size: 4vh;
            font-weight: 1000;
            margin-right: 4vh;
            text-align: center; //水平居中
            // text-shadow: 0 0 2px rgb(0, 187, 255), 0 0 10px rgb(0, 204, 255), 0 0 10px rgb(0, 213, 255), 0 0 10px rgb(0, 213, 255); //设置发光效果
            color: #7D97F1;
        }
    }

    .CharCrumbs {
        width: 20%;
        height: 3%;
        margin-left: 10.3vh;
        color: rgba(221, 221, 221, 1);
        font-size: 1.6vh;
        display: flex;
        align-items: center;
    }

    .CharBox {
        width: 93%;
        height: 70%;
        margin: 0 auto;
        margin-top: 3vh;
        background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .CharDrop {
            width: 90%;
            height: 9%;
            border: 1px solid rgba(100, 162, 255, 1);
            margin-top: 5vh;
            display: flex;
            align-items: center;

            .dark-select {

                // 修改input默认值颜色 兼容其它主流浏览器
                /deep/ input::-webkit-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

                /deep/ input::-moz-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

                /deep/ input::-ms-input-placeholder {
                    color: rgba(255, 255, 255, 1);
                }

            }

            /deep/ .el-input__inner {
                background-color: rgba(255, 255, 255, 0);
                color: rgba(255, 255, 255, 1);
                border: none; // 去掉边框
                text-align: center;
            }

            // input框 右侧的箭头
            /deep/ .el-select .el-input .el-select__caret {
                width: 3.5vh;
                height: 2vh;
                color: rgba(100, 162, 255, 1);
                font-size: 2.5vh;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 1.9vh;
                margin-right: 2vh;
            }

        }

        .RedBelow {
            width: 90%;
            height: 78%;
            display: flex;
            overflow: hidden;
            overflow-y: scroll;
            .RedLeft {
                width: 50%;
                // height: 100%;

                .RedName {
                    width: 90%;
                    height: 12%;
                    border-bottom: 1px solid rgba(100, 162, 255, 1);
                    margin-top: 4vh;
                    display: flex;
                    align-items: center;

                    input {
                        width: 67%;
                        -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                        outline: none;
                        background-color: rgba(0, 0, 0, 0);
                        border: 0px;
                        text-indent: 2vh;
                        padding: 1vh;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                    }

                }

                .RedNameOne {
                    width: 90%;
                    height: 12%;
                    border-bottom: 1px solid rgba(100, 162, 255, 1);
                    margin-top:15vh;
                    display: flex;
                    align-items: center;


                    // 修改input默认值颜色 兼容其它主流浏览器
                    /deep/ input::-webkit-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-moz-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-ms-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    // input框
                    /deep/ .el-select,
                    /deep/ .el-input,
                    /deep/ .el-input__inner {
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(255, 255, 255, 1);
                        border: none; // 去掉边框
                        font-size: 1.8vh;
                        font-weight: 300;
                    }

                    /deep/ .el-select .el-input .el-select__caret {
                        color: rgba(100, 162, 255, 1);
                    }

                    // option选项 最外层
                    /deep/ .el-select-dropdown {
                        border: none !important;
                        background: rgba(255, 255, 255, 1) !important;
                        z-index: 9999;
                    }
                }
                .CharFlexOne {
                    width: 90%;
                    height: 12%;
                    margin-top:5.68vh;
                    display: flex;
                    justify-content: space-between;   

                    .RedPicture {
                        width: 48%;
                        height: 100%;
                        border-bottom: 1px solid rgba(100, 162, 255, 1);
                        display: flex;
                        align-items: center;

                        input {
                            width: 59%;
                            -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                            outline: none;
                            background-color: rgba(0, 0, 0, 0);
                            border: 0px;
                            text-indent: 2vh;
                            padding: 1vh;
                            font-size: 1.8vh;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }

                .RedUploading {
                    width: 100%;
                    height: 30%;
                    margin-top:3vh;
                    display: flex;
                    margin-left: 3vh;
                    .RedCharacters {
                        width:17.8%;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                        height: 100%;

                        .RedPictureOne {
                            width: 100%;
                            height: 49%;
                        }
                    }

                    .RedFlex {
                        width: 72%;
                        height: 100%;
                       

                        .RedVideo {
                            width: 100%;
                            height: 100%;
                            display: flex;

                            /deep/ .el-upload {
                                width: 9vw;
                                height: 100%;
                                background-color: rgba(255, 255, 255, 0);
                                border: none;
                            }

                            ::v-deep .disUoloadBtn .el-upload--picture-card {
                                display: none;
                                /* 上传按钮隐藏 */
                            }

                            ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                                // margin-top: 4%;
                                height: 100%;
                                width: 9vw;
                            }

                            // /deep/ 后要加一个空格

                            /deep/ .el-dialog {
                                width: 70%;
                                height: 70%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .video {
                                    // width: 20vw;
                                    width: 100%;
                                    height: 100%;
                                }

                            }
                        }
                    }
                    
                }

                .ChaVideoIntrodu{
                        width:89.8%;
                    height:80%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top:6vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .ChaTitle{
                        width:95%;
                        color: rgba(255, 255, 255, 1);
                        font-size:1.8vh;
                        margin-top:2vh;
                     }
                    .RedCollText {
                        width: 95%;
                        height:32%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top:2vh;
                    }
                    }

            }

            .RedRight {
                width: 50%;
                height: 100%;
                .RedTextBox {
                    width: 90%;
                    height: 55%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top:6vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // justify-content: center;
                     .ChaTitle{
                        width:95%;
                        color: rgba(255, 255, 255, 1);
                        font-size:1.8vh;
                        margin-top:2vh;
                     }
                    .RedCollText {
                        width: 95%;
                        height:75%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top:2vh;
                        // scrollbar-base-color:red
                    }

                    ::-webkit-scrollbar {
                        width: 0.3vw;
                        height: 0.1vh;
                        background-color: rgba(213, 213, 213, 1);
                    }

                    /* 滚动条上的滚动滑块 */
                    ::-webkit-scrollbar-thumb {
                        height: 0.3vh;
                        background-color: white;
                        border-radius: 32px;
                    }
                }
               
               

                .CharFlexTwo {
                    width: 90%;
                    height: 12%;
                    margin-top: 2vh;
                    display: flex;
                    justify-content: space-between;
                    .RedPicture {
                        width: 48%;
                        height: 100%;
                        border-bottom: 1px solid rgba(100, 162, 255, 1);
                        display: flex;
                        align-items: center;

                        input {
                            width: 59%;
                            -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                            outline: none;
                            background-color: rgba(0, 0, 0, 0);
                            border: 0px;
                            text-indent: 2vh;
                            padding: 1vh;
                            font-size: 1.8vh;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }

                .CharRight {
                    width: 90%;
                    height: 30%;
                    margin-top:3vh;
                    display: flex;
                    margin-left: 3vh;
                    .RedCharacters {
                        width:25.1%;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                        height: 100%;

                        .RedPictureOne {
                            width: 100%;
                            height: 49%;
                        }
                    }

                    .RedRedPictureTwo {
                        width: 100%;
                        height: 100%;
                        display: flex;

                        /deep/ .el-upload {
                            width: 9vw;
                            height: 100%;
                            background-color: rgba(255, 255, 255, 0);
                            border: none;

                        }



                        ::v-deep .disUoloadBtn .el-upload--picture-card {
                            display: none;
                            /* 上传按钮隐藏 */
                        }

                        ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                            // margin-top: 4%;
                            height: 100%;
                            width: 9vw;
                            // line-height: 80px;
                        }

                        // /deep/ 后要加一个空格

                        /deep/ .el-dialog {
                            width: 70%;
                            height: 70%;
                            display: flex;
                            justify-content: center;
                            align-items: center;


                            .image {
                                width: 20vw;
                            }

                        }
                    }
                }
                .ChaVideoIntrodu{
                        width:89.8%;
                    height:80%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top:6vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .ChaTitle{
                        width:95%;
                        color: rgba(255, 255, 255, 1);
                        font-size:1.8vh;
                        margin-top:2vh;
                     }
                    .RedCollText {
                        width: 95%;
                        height:32%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top:2vh;
                        // scrollbar-base-color:red
                    }
                    }

            }
        }
        ::-webkit-scrollbar {
            width: 0.3vw;
            height: 0.1vh;
            background-color: rgba(125, 151, 241, 1);
        }

        /* 滚动条上的滚动滑块 */
        ::-webkit-scrollbar-thumb {
            height: 0.3vh;
            background-color: rgba(41, 98, 182, 1);
            border-radius: 32px;
        }
    }




}
</style>